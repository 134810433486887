import React, { useEffect, useState } from "react";
import RelatedProduct from "./RelatedProduct";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdditionalInfo from "./AdditionalInfo";
import ProductGallery from "./ProductGallery";
import { Helmet } from "react-helmet";

function LeftProduct() {
  const params = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [reviewData, setReviewData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProductDetails();
    getReview();
    //eslint-disable-next-line
  }, [params.id]);

  const getProductDetails = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}user/product/getOne?id=${params.id}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
          redirect: "follow",
        }
      );
      const result = await response.json();
      if (response.ok) {
        console.log(result.data);
        setProduct(result.data);
      } else {
        toast.error(result.message || "An error occurred");
        navigate("/error");
      }
    } catch (error) {
      console.error("Error:", error);
      navigate("/error");
    } finally {
      setIsLoading(false);
    }
  };

  const getReview = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}user/review/getAll?productId=${params.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setReviewData(result.data);
      })
      .catch((error) => console.log("Error:", error));
  };

  const handleAddToCart = (product) => {
    let cart = JSON.parse(localStorage.getItem("cart")) || [];
    const existingProductIndex = cart.findIndex(
      (item) => item.id === product.id
    );
    if (existingProductIndex >= 0) {
      cart[existingProductIndex].quantity += 1;
      toast("Product Qty +1");
    } else {
      cart.push({ ...product, quantity: 1 });
      toast("Product added to cart");
    }
    localStorage.setItem("cart", JSON.stringify(cart));
  };

  const calculateRatingWidth = (rating) => {
    return `${rating * 20}%`;
  };

  if (isLoading) {
    return (
      <div className="col-lg-12">
        <Helmet>
          <title>Loading Product...</title>
        </Helmet>
        <div className="product-detail accordion-detail">
          <div className="row mb-50">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <Skeleton height={400} />
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <Skeleton height={30} width={`60%`} />
              <Skeleton count={4} />
              <Skeleton height={50} />
            </div>
          </div>
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} />
        </div>
      </div>
    );
  }
  return (
    <div className="col-lg-12">
      <Helmet>
        <title>{`${product.title} - Fazal Shawl`}</title>
        <meta name="og:title" content={product.title} />
        <meta name="og:description" content={product.description} />
        <meta name="og:image" content={product.image} />
        <meta name="og:type" content="product" />
        <meta name="og:url" content={window.location.href} />
      </Helmet>
      <div className="product-detail accordion-detail">
        <div className="row mb-50">
          <div className="col-md-6 col-sm-12 col-xs-12 mb-30">
            <ProductGallery data={product} />
          </div>
          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className="detail-info d-flex flex-column">
              <h2 className="title-detail pt-5 mt-5">{product.title}</h2>
              <div className="product-detail-rating">
                <div className="pro-details-brand"></div>
                <div className="product-rate-cover text-end">
                  <div className="product-rate d-inline-block">
                    <div
                      className="product-rating"
                      style={{ width: calculateRatingWidth(product.avgRating) }}
                    ></div>
                  </div>
                  <span className="font-small ml-5 text-muted"> ({reviewData.length} reviews)</span>
                </div>
              </div>
              <div className="clearfix product-price-cover">
                <div className="product-price primary-color float-left">
                  <ins>
                    <span className="text-brand">Rs.{product.salePrice}</span>
                  </ins>
                  {product.discount > 0 && (
                    <ins>
                      <span className="old-price font-md ml-15">
                        Rs.{product.discount}
                      </span>
                    </ins>
                  )}
                </div>
              </div>
              <div className="bt-1 border-color-1 mt-15 mb-15"></div>
              <div className="short-desc mb-30">
                <p>{product.description}</p>
              </div>
              <div>Rating</div>
              <div className="product-rate font-lg d-inline-block">
                <div
                  className="product-rating"
                  style={{ width: calculateRatingWidth(product.avgRating) }}
                ></div>
              </div>
              <div className="product_sort_info font-md mb-30">
                <ul>
                  <li>
                    <i className="fi-rs-credit-card mr-5"></i> Cash on Delivery
                    available
                  </li>
                </ul>
              </div>
              {/* <div className="attr-detail attr-color mb-15">
                <strong className="mr-10">Color</strong>
                <ul className="list-filter color-filter">
                  <li>
                    <a href="##" data-color="Red">
                      <span className="product-color-red"></span>
                    </a>
                  </li>
                  <li>
                    <a href="##" data-color="Yellow">
                      <span className="product-color-yellow"></span>
                    </a>
                  </li>
                  <li className="active">
                    <a href="##" data-color="White">
                      <span className="product-color-white"></span>
                    </a>
                  </li>
                  <li>
                    <a href="##" data-color="Orange">
                      <span className="product-color-orange"></span>
                    </a>
                  </li>
                  <li>
                    <a href="##" data-color="Cyan">
                      <span className="product-color-cyan"></span>
                    </a>
                  </li>
                  <li>
                    <a href="##" data-color="Green">
                      <span className="product-color-green"></span>
                    </a>
                  </li>
                  <li>
                    <a href="##" data-color="Purple">
                      <span className="product-color-purple"></span>
                    </a>
                  </li>
                </ul>
              </div> */}
              {/* <div className="bt-1 border-color-1 mt-30 mb-30"></div> */}
              <div className="detail-extralink">
                <div className="product-extra-link2">
                  <button
                    type="submit"
                    onClick={() => handleAddToCart(product)}
                    className="button button-add-to-cart"
                    disabled={product?.quantity <=0 ? true : false}
                  >
                    {product?.quantity <=0 ? "Out Of Stock" : "Add To Cart"}
                  </button>
                </div>
                <ul className="product-meta font-md color-grey mt-50">
                  <li className="mb-5">
                    Product Code: <a href="##">{product?.productCode}</a>
                  </li>
                  <li>
                    Availability:
                    <span className="in-stock text-success ml-5">
                      {product?.quantity >= 0 ? product?.quantity : 0} Items In Stock left
                    </span>
                  </li>
                  <li className="mb-5">
                    <strong>
                      Note: Actual embroidery color and border design might
                      slightly vary from the Image.
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <AdditionalInfo id={product?.id} reviewData={reviewData} />
        <RelatedProduct id={product?.subCategoryId} />
      </div>
      <ToastContainer />
    </div>
  );
}

export default LeftProduct;
